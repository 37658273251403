<template>
  <div class="contents">
      <div class="container">
            <h1 class="center policy-page-title">Terms And Conditions</h1>

            <section class="policy-content-body col s12 pad0-xs">
                <div class="policy-block">
                    <h4 class="policy-block-title">
                        <span class="policy-num">1. </span>
                        <span>INTRODUCTION</span>
                    </h4>
                    <p class="policy-block-texts">
                        These Website Standard Terms And Conditions (these “Terms” or these “Website Standard Terms And Conditions”) 
                        contained herein on this webpage, shall govern your use of this website, including all pages within this 
                        website (collectively referred to herein below as this “Website”). These Terms apply in full force and 
                        effect to your use of this Website and by using this Website, you expressly accept all terms and conditions 
                        contained herein in full. You must not use this Website, if you have any objection to any of these Website 
                        Standard Terms And Conditions.
                    </p>
                </div>
                <div class="policy-block">
                    <h4 class="policy-block-title">
                        <span class="policy-num">2. </span>
                        <span>INTELLECTUAL PROPERTY RIGHTS</span>
                    </h4>
                    <p class="policy-block-texts">
                        Other than content you own, which you may have opted to include on this Website, under these Terms, Payrail Agency 
                        and/or its licensors own all rights to the intellectual property and material contained in this Website, and all 
                        such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, 
                        for purposes of viewing the material contained on this Website.
                    </p>
                </div>
                <div class="policy-block">
                    <h4 class="policy-block-title">
                        <span class="policy-num">3. </span>
                        <span>RESTRICTIONS</span>
                    </h4>
                    <p class="policy-block-texts">
                        You are expressly and emphatically restricted from all of the following:
                    </p>
                    <ul class="policy-list">
                        <li>publishing any Website material in any media;</li>
                        <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
                        <li>publicly performing and/or showing any Website material;</li>
                        <li>using this Website in any way that is, or may be, damaging to this Website;</li>
                        <li>using this Website in any way that impacts user access to this Website;</li>
                        <li>
                            using this Website contrary to applicable laws and regulations, or in a way that causes, 
                            or may cause, harm to the Website, or to any person or business entity;
                        </li>
                        <li>
                            engaging in any data mining, data harvesting, data extracting or any other similar activity in 
                            relation to this Website, or while using this Website;
                        </li>
                        <li>
                            using this Website to engage in any advertising or marketing;
                        </li>
                    </ul>
                    <p class="policy-block-texts">
                        Certain areas of this Website are restricted from access by you and Payrail Agency may further restrict 
                        access by you to any areas of this Website, at any time, in its sole and absolute discretion.  
                    </p>
                </div>
                <div class="policy-block">
                    <h4 class="policy-block-title">
                        <span class="policy-num">4. </span>
                        <span>ASSIGNMENT</span>
                    </h4>
                    <p class="policy-block-texts">
                        Payrail Agency shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these 
                        Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or 
                        subcontract any of your rights and/or obligations under these Terms.
                    </p>
                </div>
                <div class="policy-block">
                    <h4 class="policy-block-title">
                        <span class="policy-num">5. </span>
                        <span>YOUR CONTENT</span>
                    </h4>
                    <p class="policy-block-texts">
                        In these Website Standard Terms And Conditions, “Your Content” shall mean any audio, video, text, images or other 
                        material you choose to display on this Website. With respect to Your Content, by displaying it, you grant Payrail 
                        Agency a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, 
                        publish, translate and distribute it in any and all media.
                    </p>
                    <p class="policy-block-texts">
                        Your Content must be your own and must not be infringing on any third party’s rights. Payrail Agency reserves 
                        the right to remove any of Your Content from this Website at any time, and for any reason, without notice.
                    </p>
                </div>
                <div class="policy-block">
                    <h4 class="policy-block-title">
                        <span class="policy-num">6. </span>
                        <span>ENTIRE AGREEMENT</span>
                    </h4>
                    <p class="policy-block-texts">
                        These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire 
                        agreement between Payrail Agency and you in relation to your use of this Website, and supersede all prior 
                        agreements and understandings with respect to the same.
                    </p>
                </div>
                <div class="policy-block">
                    <h4 class="policy-block-title">
                        <span class="policy-num">7. </span>
                        <span>GOVERNING LAW & JURISDICTION</span>
                    </h4>
                    <p class="policy-block-texts">
                        These Terms will be governed by and construed in accordance with the laws of the Federal Republic of Nigeria, 
                        and you submit to the exclusive jurisdiction of the courts within Nigeria for the resolution of any disputes.
                    </p>
                </div>
            </section>
        </div>
  </div>
</template>

<script>
export default {
    name: "AgencyTerms"
}
</script>

<style src="../../assets/css/policy.css" scoped></style>