<template>
    <footer id="contact" class="scrollspy">
        <div class="row">
            <div class="container">                    
                <div class="col s12 pad0-xs* f-links-wrapper flw-1">
                    <div class="ddf">
                        <h5 class="footer-title">Resources</h5>
                        <ul class="footer-links-li">
                            <li>
                                <a href="/">Customer Stories</a>
                            </li>
                            <li>
                                <a href="/">Media</a>
                            </li>
                            <li>
                                <a href="/">FAQs</a>
                            </li>
                            <li>
                                <a href="/">Security</a>
                            </li>
                            <li>
                                <a href="/">Interest Calculator</a>
                            </li>
                            <li>
                                <a href="/">Plan Tool</a>
                            </li>
                            <li>
                                <a href="/">Blog</a>
                            </li>
                            <li>
                                <a href="/">Help Center</a>
                            </li>
                        </ul>
                    </div>
                    <div class="ddf">
                        <h5 class="footer-title">Company</h5>
                        <ul class="footer-links-li">
                            <li>
                                <a href="/" class="">About Us</a>
                            </li>
                            <li>
                                <a href="/" class="">Press</a>
                            </li>
                            <li>
                                <a href="/" class="">FAQs</a>
                            </li>
                            <li>
                                <a href="/" class="">Careers</a>
                            </li>
                            <li>
                                <a href="/" class="">Ambassadors</a>
                            </li>
                            <li>
                                <a href="/agency/privacy" class="">Privacy</a>
                            </li>
                            <li>
                                <a href="/agency/terms" class="">Terms</a>
                            </li>
                        </ul>
                    </div>
                    <div class="ddf">
                        <h5 class="footer-title">Product</h5>
                        <ul class="footer-links-li">
                            <li>
                                <a href="/">Saving Plans</a>
                            </li>
                            <li>
                                <a href="/">Insurance</a>
                            </li>
                            <li>
                                <a href="/">Payrail Wallet</a>
                            </li>
                            <li>
                                <a href="/">Rail Fund</a>
                            </li>
                            <li>
                                <a href="/">Features</a>
                            </li>
                            <li>
                                <a href="/">Give suggestions?</a>
                            </li>
                        </ul>
                    </div>
                    <div class="ddf">
                        <h5 class="footer-title">Accounts</h5>
                        <ul class="footer-links-li">
                            <li>
                                <a href="/">Urban</a>
                            </li>
                            <li>
                                <a href="/">Business</a>
                            </li>
                            <li>
                                <a href="/">Agency</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col s12 pad0-xs* f-links-wrapper flw-2">
                    <div class="ddf sa-ddf">
                        <h5 class="footer-title">Contact</h5>
                        <ul class="footer-links-li social-footer-links">
                            <li class="sa-li">
                                <a href="mailto:support@payrail.co" class="">support@payrail.co</a> <br /> 
                                <span>Layi Yusuf Cresent, Lekki Phase 1, Lagos</span>
                            </li>
                            <li class="agency-stuff">
                                <a href="https://twitter.com/" target="_blank" rel="noreferrer" class="social-link">
                                    <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/twitter-yellow.svg" alt="twitter" class="hover-scale" />
                                </a>
                                <a href="https://whatsapp.com/" target="_blank" rel="noreferrer" class="social-link">
                                    <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/whatsapp-yellow.svg" alt="whatsapp" class="hover-scale" />
                                </a>
                                <a href="https://facebook.com/" target="_blank" rel="noreferrer" class="social-link">
                                    <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/facebook-yellow.svg" alt="facebook" class="hover-scale" />
                                </a>
                                <a href="https://linkedin.com/company/" target="_blank" rel="noreferrer" class="social-link">
                                    <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/linkedin-yellow.svg" alt="linkedin" class="hover-scale" />
                                </a>
                                <a href="https://instagram.com/payrail_agency" target="_blank" rel="noreferrer" class="social-link">
                                    <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/instagram-yellow.svg" alt="instagram" class="hover-scale" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="ddf">
                        <ul class="footer-links-li social-footer-links">
                            <li>
                                <span class="download-txt">
                                    Download the Payrail app today for a 100% mobile banking experience. 
                                    Available on iOS and Android.
                                </span>
                            </li>
                            <li class="footer-appstore-wrapper agency-stuff">
                                <a href="https://play.google.com/store/apps/details?id=com.angala.payrail" target="_blank" rel="noopener noreferrer">
                                    <button class="app-store hover-scale">
                                        <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/google-playstore-yellow.svg" alt="google store" />
                                    </button>
                                </a>

                                <a href="https://apps.apple.com/ng/app/payrail-agency/id1588794187" target="_blank" rel="noopener noreferrer">
                                    <button class="app-store hover-scale">
                                        <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/apple-appstore-yellow.svg" alt="apple store" />
                                    </button>
                                </a>

                                <a href="#">
                                    <button class="app-store hover-scale">
                                        <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/browser-yellow.svg" alt="web browser" />
                                    </button>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col s12 pad0-xs footer-hr-div">
                    <hr />
                    <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/payrail-angala.png" alt="Payrail and Angala" class="payrail-anagala n-img-1" />
                    <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/payrail-angala-light.png" alt="Payrail and Angala" class="payrail-anagala n-img-2" />
                    <hr />
                </div>
                <div class="regulators-section col pad0-xs s12">
                    <div class="flex-div regulator-subscribe-wrapper">
                        <div class="regulators-wrapper">
                            <a href="http://" class="hover-scale" target="_blank" rel="noopener noreferrer">
                                <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/sec.png" alt="sec" />
                            </a>
                            <a href="http://" class="hover-scale" target="_blank" rel="noopener noreferrer">
                                <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/cbn.png" alt="cbn" />
                            </a>
                            <a href="http://" class="hover-scale last-regulator" target="_blank" rel="noopener noreferrer">
                                <img src="https://websites-images-1.s3.us-east-2.amazonaws.com/payrail-images/images/ndic.png" alt="ndic" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="cookies-notice col pad0-xs s12">
                    <div class="p-div text-justify">
                        <p>
                            Payrail is product of 
                            <a href="https://angalafintech.com" class="pry-color" target="_blank" rel="noopener noreferrer"> Angala Fintech </a> is a company 
                            registered in Nigeria RC - 1765120. All rights reserved. Payrail is financial service 
                            provided by Angala Microfinance Bank Limited RC *******, licensed and regulated by 
                            the Central Bank of Nigeria (CBN). We empower individuals with credit, simple payments 
                            solutions, high-yield investment opportunities, and easy-to-use tools for personal 
                            financial management.Any unauthorized redistribution or reproduction of any copyrighted 
                            materials on this website is strictly prohibited. Other product and company names 
                            are trademarks of their respective owners. This website contains simulated images; 
                            actual appearance may vary.
                        </p>
                        <p>
                            Our Insurance  are managed by our fund management 
                            <a href="https://angalafintech.com" class="pry-color" target="_blank" rel="noopener noreferrer"> Angala Insurtech </a> RC - 1798557 
                            and partners and made available to our clients through our web and mobile applications. 
                            The fund manager for each mutual fund is disclosed on every mutual fund’s profile page. 
                            Any historical returns or expected returns are hypothetical in nature and may not reflect 
                            actual future performance. 
                            <a href="/" class="pry-color" target="_blank" rel="noopener noreferrer"> Check the list of Insurance avaible on Payrail.↗ </a>
                        </p>
                        <p>
                            Google Play and the Google Play logo are trademarks of Google LLC. Apple, the Apple logo, 
                            and iPhone are trademarks of Apple Inc. registered in the U.S.
                        </p>
                        <p>
                            This website uses <a href="/" class="pry-color" target="_blank" rel="noopener noreferrer"> Cookies ↗ </a>  
                            We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. 
                            The content on this website does not constitute a complete description of Payrail or Angala Fintech services. 
                            By using this website, you accept our <a href="/agency/terms" class="pry-color" target="_blank" rel="noopener noreferrer"> Terms of Use </a> 
                            and <a href="/agency/privacy" class="pry-color" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
                        </p>
                    </div>
                </div>
                <div class="col s12 pad0-xs copyright-div">
                    <p>
                        © Angala Financial Technologies Limited. All Rights Reserved.
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "AgencyFooter"
}
</script>

<style src="../assets/css/footer.css"></style>